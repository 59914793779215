import Amplify from '@aws-amplify/api';

// ****************************** Import CSS ******************************** //

import "./index.css";

// ****************************** Import Images ******************************** //

// ** LOADING SPINNER - GCS Computer ** //
import "./assets/logos/gcs-logo-pink-comp-only.png";

// ** Banner Inages ** //
import "./assets/icons/snowflakes-white.png";

// ** Hero Inages ** //
import "./assets/themed-photos/hello-world.jpg";
import "./assets/themed-photos/pink-binary-numbers.jpg";
import "./assets/summer-camp-2023/gcs-summer-camp-2023-0.jpg";
import "./assets/class-photos/margaret-zoe.jpg";
import "./assets/class-photos/sheila-helping-girls.jpg";
import "./assets/summer-camp-2023/summer-camp-2023-group-photo.jpg";
import "./assets/summer-camp-2023/gcs-summer-camp-2023-21.jpeg";

// ** HTML Bracket Images ** //
import "./assets/icons/white-brackets.png";
import "./assets/icons/open-p-tag.png";
import "./assets/icons/close-p-tag.png";

// ** WINTER CAMP 2023 - Themed Flyer ** //
import "./assets/flyers/girls-code-sav-winter-camp-2023-1.png";

// ** FALL SESSIONS 2023 - Photos for Carousel ** //
import "./assets/fall-sessions-2023/3-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/4-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/6-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/7-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/9-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/10-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/21-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/37-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/71-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/82-girlscode-BJP-fall-2023.jpg";
import "./assets/fall-sessions-2023/89-girlscode-BJP-fall-2023.jpg";

// ** SUMMER CAMP 2023 - Themed Photos for Gallery ** //
import "./assets/themed-photos/airplane-controls.jpg";
import "./assets/themed-photos/amelia.jpg";
import "./assets/themed-photos/bessie-coleman.jpg";
import "./assets/themed-photos/airplane.jpg";
import "./assets/sponsor-logos/fly-corps-off-white-bkgnd.png";

// ** SUMMER CAMP 2023 - Computer Screen ** //
import "./assets/themed-photos/html-button.png";
import "./assets/themed-photos/css-button.png";
import "./assets/themed-photos/hello-world-computer-screen.png";

// ** 2023 - Sponsors ** //
import "./assets/sponsor-logos/business-innovation-group.jpg";
import "./assets/sponsor-logos/city-of-savannah.png";
import "./assets/sponsor-logos/fly-corps.png";
import "./assets/sponsor-logos/howmet-aerospace.png";
import "./assets/sponsor-logos/howmet-womens-network.png";
import "./assets/sponsor-logos/sams-club-blue-bkgnd.png";
import "./assets/sponsor-logos/live-oak-public-libraries.png";
import "./assets/sponsor-logos/bud-johnson-photography.png";

// **************************** Add Top Nav & Footer ************************** //

import './nav-header-footer/nav-header-footer.js';
import './nav-header-footer/nav-header-footer.css';

// ************ Add Email Signup *************** //

import './nav-header-footer/email-contact-new-classes.js';

// **************************** Loading Spinner ****************************** //

$(window).on('load', function(){
  document.getElementById("loading-spinner").style.display = "block";
  setTimeout(removeSpinner, 1000);
});

function removeSpinner(){
  $( "#loading-spinner" ).fadeOut(100, function() {
    $( "#loading-spinner" ).remove();
  });
}

// **************** Function Spinner Show & Hide ******************** //

  function spinnerShow() {
      document.getElementById("function-spinner").style.display = "block";
  }

  function spinnerHide() {
      document.getElementById("function-spinner").style.display = "none";
  }

// **************************** Change InnerHTML of Hero Button for Particular Screen Width ****************************** //

const viewPortWidth = window.innerWidth;

// console.log('ViewPortWidth:', viewPortWidth);

const aboutUsButton = document.getElementById('about-us-button');

const campsButton = document.getElementById('camps-button');

if (viewPortWidth == '1280') {

  aboutUsButton.innerHTML = `ABOUT US`;

  campsButton.innerHTML = `SEE CAMPS & CLASSES`;
}
