import { Amplify } from 'aws-amplify';

// ********* AWS Configs ******************** //

import { configDev } from '../aws-config-dev';

if (process.env.NODE_ENV === 'development') {
  // console.log("MODE IS: DEVLOPEMNT");
  Amplify.configure(configDev);
}

import { configProd } from '../aws-config-prod';

if (process.env.NODE_ENV === 'production') {
  // console.log("MODE IS: PRODUCTION");
  Amplify.configure(configProd);
}

// ********* Amplify Imports ******************** //

  import { fetchAuthSession, getCurrentUser} from 'aws-amplify/auth';

  import { fetchUserAttributes } from 'aws-amplify/auth';

  import { signOut } from 'aws-amplify/auth';

// *************************** Import CSS *************************** //

import "./nav-header-footer.css";

// ************************** Import Images ************************ //

import "../assets/logos/gcs-wordmark-code-pink.png";
import "../assets/logos/creative-coast-logo.png";
import "../assets/icons/envelope.svg";

// **************** Function Spinner Show & Hide ******************** //

  function spinnerShow() {
      document.getElementById("function-spinner").style.display = "block";
  }

  function spinnerHide() {
      document.getElementById("function-spinner").style.display = "none";
  }

// ***** Constants *********** //

let currentUser = null;
let currentUserInfo = null;

// *************************************************** Insert Navigation Menu **************************************************** //

class FirstNav extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

    <div class="container">

      <nav class="navbar navbar-expand-lg fixed-top bg-sweet-pink" aria-label="Navigation">

        <div class="container">

          <!-- Girls Code Savannah Logo -->

          <a class="navbar-brand" href="./index.html">
            <img src="gcs-wordmark-code-pink.png" height="60px" class="navbar-wordmark d-inline-block align-top mt-2" alt="Girls Code Savannah Logo" />
            <span class="hidden">Girls Code Savannah</span>
          </a>

          <!-- Toggle Button for Mobile Nav -->

          <button class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
            type="button" data-bs-toggle="collapse" data-bs-target="#navbar-items"
            aria-controls="navbar-items" aria-expanded="false" aria-label="Toggle navigation">
            <span class="toggler-icon top-bar"></span>
            <span class="toggler-icon middle-bar"></span>
            <span class="toggler-icon bottom-bar"></span>
          </button>

          <!-- Navigation Menu -->

          <div id="navbar-items" class="collapse navbar-collapse">

            <ul class="navbar-nav ms-auto text-center mb-2 mb-sm-0">

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="index.html">Home</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="camps-classes.html">Camps & Classes</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="instructors.html">Instructors</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="faqs.html">FAQs</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="about.html">About</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="contact.html">Contact</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="donate.html">Donate</a>
              </li>

              <li class="nav-item mx-auto mx-lg-1">
                <a class="nav-link bold" href="student-websites.html">Student Websites</a>
              </li>


              <!-- MY PAGE -->
              <li id="my-page-nav-link" class="nav-item mx-auto ms-lg-1 me-lg-2 hidden">
                <a class="nav-link bold" href="my-page.html">My Page</a>
              </li>

              <!-- LOG IN BUTTON -->
              <li id="login-nav-button" class="nav-item mx-auto ms-lg-2 me-lg-3 mt-1 hidden">
                <a href="login.html" class="nav-link-auth bold btn btn-primary">LOG IN</a>
              </li>

              <!-- LOG OUT BUTTON -->
              <li id="logout-nav-button" class="nav-item mx-auto me-lg-3 mt-1 hidden">
                <a href="#" class="nav-link-auth bold btn btn-primary">LOG OUT</a>
              </li>

              <!-- <li class="nav-item mx-auto ms-lg-1 me-lg-2 pt-2 pt-lg-0">
                <a href="https://www.facebook.com/yourdogsbest" target="_blank">
                  <img src="facebook-icon.svg" width="30" height="30" class="Facebook icon" alt="Facebook icon">
                </a>
              </li> -->

              <!-- <li class="nav-item mx-auto ms-lg-1 me-lg-2 pt-4 pt-lg-0">
                <a href="https://www.instagram.com/yourdogsbest" target="_blank">
                  <img src="instagram-icon.svg" width="30" height="30" class="img" alt="Instagram icon">
                </a>
              </li> -->

            </ul>

          </div>

        </div>

      </nav>

    </div> <!-- container -->

    `
  }
}

// ************************************************ Insert General Contact Info *********************************************************** //

class Contact extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

    <div class="container">

      <!-- BEGIN: Contact Text -->

      <div class="col-12 col-lg-5 text-center mx-auto mt-4">

        <p class="text-hot-pink text-center mt-3 serif-heading bold text-size-h2">We'd love to hear from you&#33;</p>

        <p class="text-purple text-center mt-3 serif-heading bold text-size-h3">General questions?</p>

        <p class="text-purple text-center mt-3 serif-heading bold text-size-h3">Looking to collaborate?</p>

        <p class="text-purple text-center mt-3 serif-heading bold text-size-h3">Seeking to support our efforts?</p>

      </div>

      <!-- END Contact Text -->

      <!-- BEGIN: Email Icon -->

      <div class="mt-2 text-center mx-auto">

        <a href=mailto:info@girlscodesavannah.org?subject=Inquiry%20From%20Girls%20Code%20Savannah%20Website>
          <button class="col-2 email-button mx-auto text-center">
            <i class="bi bi-envelope-at-fill"></i>
          </button>
        </a>

      </div>

      <!-- END: Email Icon -->

      <!-- BEGIN: Email Address -->

      <div class="mb-4 text-center mx-auto">

        <p class="text-h3 text-purple mt-3 mb-1">Email Us</p>

        <a class="text-purple text-h3" href=mailto:info@girlscodesavannah.org?subject=Inquiry%20From%20Girls%20Code%20Savannah%20Website>info@girlscodesavannah.org</a>

      </div>

      <!-- END: Email Address -->


      <!-- END: Email Section -->

      <div class="col-12 col-lg-5 text-center mx-auto mt-4 ps-2">

        <p class="text-dark-blue text-start text-md-center ps-2">
          Also, if you are an administrator, teacher, or support staff at a Savannah Chatham County Public School and are interested
          in us holding an on-site info session to help young women at your school learn more about our program, please let us know.
        </p>

      </div>

    </div> <!-- container -->

    `
  }
}

// ************************************************ Insert Lesli Contact Info *********************************************************** //

class LesliContact extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

      <a class="text-purple" 
      href=mailto:lesli@girlscodesavannah.org?subject=Inquiry%20From%20Girls%20Code%20Savannah%20Website>lesli@girlscodesavannah.org.</a>
    `
  }
}

// ************************************************ Email Contact Form *********************************************************** //

class ContactForNewClasses extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

  <div class="container">

    <!-- BEGIN: Title Section -->

    <div class="row mt-1">

      <div class="col-10 mx-auto text-center">
        
        <p class="serif-heading bold mx-auto text-center text-size-h1 text-white mt-1">
          Get Notified About Our New Camps & Classes
        </p>

      </div>

    </div>

    <!-- END: Title Section -->

    <form action="" name="submit-to-google-sheet">

      <div class="row col-12 col-lg-10 mx-auto">

        <div class="col-10 col-md-4 col-lg-3 mb-3 mb-lg-0 mx-auto">
          <input class="form-control me-auto" type="text" id="firstName" name="firstName" placeholder="First Name" aria-label="Enter first name">
        </div>

        <div class="col-10 col-md-4 col-lg-3 mb-3 mb-lg-0 mx-auto">
          <input class="form-control me-auto" type="text" id="lastName" name="lastName" placeholder="Last Name" aria-label="Enter last name">
        </div>

        <div class="col-10 col-md-4 col-lg-3 mb-3 mb-lg-0 mx-auto">
          <input class="form-control me-auto" type="email" id="email" name="email" placeholder="Email Address" aria-label="Enter email address">
        </div>

        <div class="col-10 col-md-2 col-lg-2 text-center mx-auto">
          <button type="submit" class="submit-button btn bg-purple">
              <span id="spinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span id="submit-text">Submit</span>
          </button>
        </div>

      </div>

      
      <!-- BEGIN: Modal -->

      <div id="emailModal" class="modal">

        <!-- BEGIN: Modal Content -->

        <div class="modal-content">
          
          <span class="close"><strong>X</strong></span>
        
          <img src="gcs-wordmark-code-pink.png" class="modal-logo" alt="Girls Code Savannah wordmark"/>

          <hr>
            
          <p id="modal-heading" class="text-purple text-size-h3 bold text-center mx-auto mt-0 pt-0"></p>
          <p id="modal-text" class="text-purple text-size-h4 text-center mx-auto mt-0 pt-0 py-3"></p>

        </div>
 
      </div>

      <!-- END: Modal -->

    </form>

  </div>

    `
  }
}

// ************************************************* Supportive Words ***************************************** //

class EncouragePopup extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

 <form action="" name="encouraging-words-form" id="encouraging-words-form">

  <div class="modal fade" id="encouraging-words-modal" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        
        <div class="modal-content">

      <!-- ******* BEGIN: MODAL HEADER (Image, Title, X button) ******* -->

          <div class="modal-header">

            <img class="gcs-wordmark" src="gcs-wordmark-code-pink.png"/>
            
            <button type="button" id="x-button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

          </div>

      <!-- ******* END: MODAL HEADER (Image, Title, X button) ******* -->

      <!-- ******* BEGIN: MODAL BODY ******* -->

          <div class="modal-body row">

            <div class="col-lg-12">

            <p class="serif-heading bold text-hot-pink text-size-h3 text-start mx-0 px-0" id="staticBackdropLabel">Your Encouraging Words Mean A Lot To These Girls!</p>

                <!-- First & Last Name -->

                <div class="row">

                  <div class="col-6 form-group">
                    <label for="firstName" class="col-form-label text-dark-blue ps-1">First Name<span class="text-hot-pink">&#42;</span></label>
                    <input type="text" class="form-control" id="firstName" name="firstName" placeholder="Enter First Name" aria-label="Enter first name" required>
                  </div>

                  <div class="col-6 form-group">
                    <label for="lastName" class="col-form-label text-dark-blue ps-1">Last Name<span class="text-hot-pink">&#42;</span></label>
                    <input type="text" class="form-control" id="lastName" name="lastName" placeholder="Enter Last Name" required>
                  </div>

                </div>

                <!-- City & State -->

                <div class="row">

                  <div class="col-6 form-group">
                    <label for="city" class="col-form-label text-dark-blue ps-1">City<span class="text-hot-pink">&#42;</span></label>
                    <input type="text" class="form-control" id="city" name="city" placeholder="Enter City" required>
                  </div>

                  <div class="col-6 form-group">
                    <label for="state" class="col-form-label text-dark-blue ps-1">State or Country<span class="text-hot-pink">&#42;</span></label>
                        <select class="form-control" id="state" name="state" required>
      <option value="">Select&nbsp;&#8595;</option>              
      <option value="AK">Alaska</option>
      <option value="AL">Alabama</option>
      <option value="AR">Arkansas</option>
      <option value="AZ">Arizona</option>
      <option value="CA">California</option>
      <option value="CO">Colorado</option>
      <option value="CT">Connecticut</option>
      <option value="DC">District of Columbia</option>
      <option value="DE">Delaware</option>
      <option value="FL">Florida</option>
      <option value="GA">Georgia</option>
      <option value="HI">Hawaii</option>
      <option value="IA">Iowa</option>
      <option value="ID">Idaho</option>
      <option value="IL">Illinois</option>
      <option value="IN">Indiana</option>
      <option value="KS">Kansas</option>
      <option value="KY">Kentucky</option>
      <option value="LA">Louisiana</option>
      <option value="MA">Massachusetts</option>
      <option value="MD">Maryland</option>
      <option value="ME">Maine</option>
      <option value="MI">Michigan</option>
      <option value="MN">Minnesota</option>
      <option value="MO">Missouri</option>
      <option value="MS">Mississippi</option>
      <option value="MT">Montana</option>
      <option value="NC">North Carolina</option>
      <option value="ND">North Dakota</option>
      <option value="NE">Nebraska</option>
      <option value="NH">New Hampshire</option>
      <option value="NJ">New Jersey</option>
      <option value="NM">New Mexico</option>
      <option value="NV">Nevada</option>
      <option value="NY">New York</option>
      <option value="OH">Ohio</option>
      <option value="OK">Oklahoma</option>
      <option value="OR">Oregon</option>
      <option value="PA">Pennsylvania</option>
      <option value="PR">Puerto Rico</option>
      <option value="RI">Rhode Island</option>
      <option value="SC">South Carolina</option>
      <option value="SD">South Dakota</option>
      <option value="TN">Tennessee</option>
      <option value="TX">Texas</option>
      <option value="UT">Utah</option>
      <option value="VA">Virginia</option>
      <option value="VT">Vermont</option>
      <option value="WA">Washington</option>
      <option value="WI">Wisconsin</option>
      <option value="WV">West Virginia</option>
      <option value="WY">Wyoming</option>
      <option value="AF">Afghanistan</option>
    <option value="AX">Aland Islands</option>
    <option value="AL">Albania</option>
    <option value="DZ">Algeria</option>
    <option value="AS">American Samoa</option>
    <option value="AD">Andorra</option>
    <option value="AO">Angola</option>
    <option value="AI">Anguilla</option>
    <option value="AQ">Antarctica</option>
    <option value="AG">Antigua and Barbuda</option>
    <option value="AR">Argentina</option>
    <option value="AM">Armenia</option>
    <option value="AW">Aruba</option>
    <option value="AU">Australia</option>
    <option value="AT">Austria</option>
    <option value="AZ">Azerbaijan</option>
    <option value="BS">Bahamas</option>
    <option value="BH">Bahrain</option>
    <option value="BD">Bangladesh</option>
    <option value="BB">Barbados</option>
    <option value="BY">Belarus</option>
    <option value="BE">Belgium</option>
    <option value="BZ">Belize</option>
    <option value="BJ">Benin</option>
    <option value="BM">Bermuda</option>
    <option value="BT">Bhutan</option>
    <option value="BO">Bolivia</option>
    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
    <option value="BA">Bosnia and Herzegovina</option>
    <option value="BW">Botswana</option>
    <option value="BV">Bouvet Island</option>
    <option value="BR">Brazil</option>
    <option value="IO">British Indian Ocean Territory</option>
    <option value="BN">Brunei Darussalam</option>
    <option value="BG">Bulgaria</option>
    <option value="BF">Burkina Faso</option>
    <option value="BI">Burundi</option>
    <option value="KH">Cambodia</option>
    <option value="CM">Cameroon</option>
    <option value="CA">Canada</option>
    <option value="CV">Cape Verde</option>
    <option value="KY">Cayman Islands</option>
    <option value="CF">Central African Republic</option>
    <option value="TD">Chad</option>
    <option value="CL">Chile</option>
    <option value="CN">China</option>
    <option value="CX">Christmas Island</option>
    <option value="CC">Cocos (Keeling) Islands</option>
    <option value="CO">Colombia</option>
    <option value="KM">Comoros</option>
    <option value="CG">Congo</option>
    <option value="CD">Congo, Democratic Republic of the Congo</option>
    <option value="CK">Cook Islands</option>
    <option value="CR">Costa Rica</option>
    <option value="CI">Cote D'Ivoire</option>
    <option value="HR">Croatia</option>
    <option value="CU">Cuba</option>
    <option value="CW">Curacao</option>
    <option value="CY">Cyprus</option>
    <option value="CZ">Czech Republic</option>
    <option value="DK">Denmark</option>
    <option value="DJ">Djibouti</option>
    <option value="DM">Dominica</option>
    <option value="DO">Dominican Republic</option>
    <option value="EC">Ecuador</option>
    <option value="EG">Egypt</option>
    <option value="SV">El Salvador</option>
    <option value="GQ">Equatorial Guinea</option>
    <option value="ER">Eritrea</option>
    <option value="EE">Estonia</option>
    <option value="ET">Ethiopia</option>
    <option value="FK">Falkland Islands (Malvinas)</option>
    <option value="FO">Faroe Islands</option>
    <option value="FJ">Fiji</option>
    <option value="FI">Finland</option>
    <option value="FR">France</option>
    <option value="GF">French Guiana</option>
    <option value="PF">French Polynesia</option>
    <option value="TF">French Southern Territories</option>
    <option value="GA">Gabon</option>
    <option value="GM">Gambia</option>
    <option value="GE">Georgia</option>
    <option value="DE">Germany</option>
    <option value="GH">Ghana</option>
    <option value="GI">Gibraltar</option>
    <option value="GR">Greece</option>
    <option value="GL">Greenland</option>
    <option value="GD">Grenada</option>
    <option value="GP">Guadeloupe</option>
    <option value="GU">Guam</option>
    <option value="GT">Guatemala</option>
    <option value="GG">Guernsey</option>
    <option value="GN">Guinea</option>
    <option value="GW">Guinea-Bissau</option>
    <option value="GY">Guyana</option>
    <option value="HT">Haiti</option>
    <option value="HM">Heard Island and Mcdonald Islands</option>
    <option value="VA">Holy See (Vatican City State)</option>
    <option value="HN">Honduras</option>
    <option value="HK">Hong Kong</option>
    <option value="HU">Hungary</option>
    <option value="IS">Iceland</option>
    <option value="IN">India</option>
    <option value="ID">Indonesia</option>
    <option value="IR">Iran, Islamic Republic of</option>
    <option value="IQ">Iraq</option>
    <option value="IE">Ireland</option>
    <option value="IM">Isle of Man</option>
    <option value="IL">Israel</option>
    <option value="IT">Italy</option>
    <option value="JM">Jamaica</option>
    <option value="JP">Japan</option>
    <option value="JE">Jersey</option>
    <option value="JO">Jordan</option>
    <option value="KZ">Kazakhstan</option>
    <option value="KE">Kenya</option>
    <option value="KI">Kiribati</option>
    <option value="KP">Korea, Democratic People's Republic of</option>
    <option value="KR">Korea, Republic of</option>
    <option value="XK">Kosovo</option>
    <option value="KW">Kuwait</option>
    <option value="KG">Kyrgyzstan</option>
    <option value="LA">Lao People's Democratic Republic</option>
    <option value="LV">Latvia</option>
    <option value="LB">Lebanon</option>
    <option value="LS">Lesotho</option>
    <option value="LR">Liberia</option>
    <option value="LY">Libyan Arab Jamahiriya</option>
    <option value="LI">Liechtenstein</option>
    <option value="LT">Lithuania</option>
    <option value="LU">Luxembourg</option>
    <option value="MO">Macao</option>
    <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
    <option value="MG">Madagascar</option>
    <option value="MW">Malawi</option>
    <option value="MY">Malaysia</option>
    <option value="MV">Maldives</option>
    <option value="ML">Mali</option>
    <option value="MT">Malta</option>
    <option value="MH">Marshall Islands</option>
    <option value="MQ">Martinique</option>
    <option value="MR">Mauritania</option>
    <option value="MU">Mauritius</option>
    <option value="YT">Mayotte</option>
    <option value="MX">Mexico</option>
    <option value="FM">Micronesia, Federated States of</option>
    <option value="MD">Moldova, Republic of</option>
    <option value="MC">Monaco</option>
    <option value="MN">Mongolia</option>
    <option value="ME">Montenegro</option>
    <option value="MS">Montserrat</option>
    <option value="MA">Morocco</option>
    <option value="MZ">Mozambique</option>
    <option value="MM">Myanmar</option>
    <option value="NA">Namibia</option>
    <option value="NR">Nauru</option>
    <option value="NP">Nepal</option>
    <option value="NL">Netherlands</option>
    <option value="AN">Netherlands Antilles</option>
    <option value="NC">New Caledonia</option>
    <option value="NZ">New Zealand</option>
    <option value="NI">Nicaragua</option>
    <option value="NE">Niger</option>
    <option value="NG">Nigeria</option>
    <option value="NU">Niue</option>
    <option value="NF">Norfolk Island</option>
    <option value="MP">Northern Mariana Islands</option>
    <option value="NO">Norway</option>
    <option value="OM">Oman</option>
    <option value="PK">Pakistan</option>
    <option value="PW">Palau</option>
    <option value="PS">Palestinian Territory, Occupied</option>
    <option value="PA">Panama</option>
    <option value="PG">Papua New Guinea</option>
    <option value="PY">Paraguay</option>
    <option value="PE">Peru</option>
    <option value="PH">Philippines</option>
    <option value="PN">Pitcairn</option>
    <option value="PL">Poland</option>
    <option value="PT">Portugal</option>
    <option value="PR">Puerto Rico</option>
    <option value="QA">Qatar</option>
    <option value="RE">Reunion</option>
    <option value="RO">Romania</option>
    <option value="RU">Russian Federation</option>
    <option value="RW">Rwanda</option>
    <option value="BL">Saint Barthelemy</option>
    <option value="SH">Saint Helena</option>
    <option value="KN">Saint Kitts and Nevis</option>
    <option value="LC">Saint Lucia</option>
    <option value="MF">Saint Martin</option>
    <option value="PM">Saint Pierre and Miquelon</option>
    <option value="VC">Saint Vincent and the Grenadines</option>
    <option value="WS">Samoa</option>
    <option value="SM">San Marino</option>
    <option value="ST">Sao Tome and Principe</option>
    <option value="SA">Saudi Arabia</option>
    <option value="SN">Senegal</option>
    <option value="RS">Serbia</option>
    <option value="CS">Serbia and Montenegro</option>
    <option value="SC">Seychelles</option>
    <option value="SL">Sierra Leone</option>
    <option value="SG">Singapore</option>
    <option value="SX">Sint Maarten</option>
    <option value="SK">Slovakia</option>
    <option value="SI">Slovenia</option>
    <option value="SB">Solomon Islands</option>
    <option value="SO">Somalia</option>
    <option value="ZA">South Africa</option>
    <option value="GS">South Georgia and the South Sandwich Islands</option>
    <option value="SS">South Sudan</option>
    <option value="ES">Spain</option>
    <option value="LK">Sri Lanka</option>
    <option value="SD">Sudan</option>
    <option value="SR">Suriname</option>
    <option value="SJ">Svalbard and Jan Mayen</option>
    <option value="SZ">Swaziland</option>
    <option value="SE">Sweden</option>
    <option value="CH">Switzerland</option>
    <option value="SY">Syrian Arab Republic</option>
    <option value="TW">Taiwan, Province of China</option>
    <option value="TJ">Tajikistan</option>
    <option value="TZ">Tanzania, United Republic of</option>
    <option value="TH">Thailand</option>
    <option value="TL">Timor-Leste</option>
    <option value="TG">Togo</option>
    <option value="TK">Tokelau</option>
    <option value="TO">Tonga</option>
    <option value="TT">Trinidad and Tobago</option>
    <option value="TN">Tunisia</option>
    <option value="TR">Turkey</option>
    <option value="TM">Turkmenistan</option>
    <option value="TC">Turks and Caicos Islands</option>
    <option value="TV">Tuvalu</option>
    <option value="UG">Uganda</option>
    <option value="UA">Ukraine</option>
    <option value="AE">United Arab Emirates</option>
    <option value="GB">United Kingdom</option>
    <option value="UY">Uruguay</option>
    <option value="UZ">Uzbekistan</option>
    <option value="VU">Vanuatu</option>
    <option value="VE">Venezuela</option>
    <option value="VN">Viet Nam</option>
    <option value="VG">Virgin Islands, British</option>
    <option value="VI">Virgin Islands, U.s.</option>
    <option value="WF">Wallis and Futuna</option>
    <option value="EH">Western Sahara</option>
    <option value="YE">Yemen</option>
    <option value="ZM">Zambia</option>
    <option value="ZW">Zimbabwe</option>
      </select>
                  </div>

                </div>

                <!-- Title -->

                <div class="form-group">
                  <label for="title" class="col-form-label text-dark-blue ps-1">Title</label>
                  <input type="text" class="form-control" id="title" name="title" placeholder="Enter Title">
                  <span class="ps-1 text-size-h5 pb-2">(Note: Proud Parent is a great title too!)</span>
                </div>

                <!-- Company -->

                <div class="form-group">
                  <label for="company" class="col-form-label text-dark-blue ps-1">Company</label>
                  <input type="text" class="form-control" id="company" name="company" placeholder="Enter Company">
                </div>

            </div>

          <!-- Message Text -->

          <div class="col-12">

              <!-- Message -->

              <div class="form-group">
                <label for="message" class="col-form-label text-dark-blue ps-1">Message<span class="text-hot-pink">&#42;</span></label>
                  <textarea class="form-control" id="message" name="message" rows="4" placeholder="Enter An Encouraging Message" required></textarea>
              </div>

          </div>

      </div>

      <!-- ******* END: MODAL BODY ******* -->

      <!-- ******* BEGIN: MODAL FOOTER ******* -->

      <div class="modal-footer">
        <p id="result-text" class="serif-heading bold text-purple text-size-h4 text-center"></p>
          <button id="btn-close" type="button" class="btn btn-secondary text-center mx-auto bg-hot-pink" data-bs-dismiss="modal">Close</button>
          <button id="btn-send-message" type="submit" class="btn btn-primary bg-purple">
          <span id="spinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span id="submit-text">Send Encouragement</span>
          </button>
      </div>

      <!-- ******* END: MODAL FOOTER ******* -->

       </form>

      </div> <!-- modal-content -->
    </div> <!-- modal-dialog -->

    

  </div> <!-- modal -->

 

    `
  }
}

// ************************************************* Insert Footer ***************************************** //

class Footer extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

    <div class="container bg-white">

      <div class="row col-lg-6 mx-auto mt-4">

        <!-- Gender-Inclusive Language -->

        <p class="mt-0 pb-2 text-size-h5 text-dark-blue">While our primary mission is to increase the participation of girls and women in STEM-based fields, 
        Girls Code Savannah welcomes all genders to attend our girl-focused camps and classes.</p>

      </div

      <div class="row justify-content-md-center mt-4">

        <!-- BEGIN: GCS Wordmark -->
    
        <div class="col-md-auto">
          <a href="./index.html">
            <img class="footer-logo-img" height="40px" src="gcs-wordmark-code-pink.png" alt="Girls Code Savannah wordmark">
          </a>
        </div>

       <!-- END: GCS Wordmark --> 
  
      </div>

        <!-- 501(c)3 -->

        <p class="copyright mt-4 text-size-h5 text-dark-blue">Girls Code Savannah is a registered 501(c)3. EIN: 99-2260202</p>

        <!-- Copyright -->

        <p class="copyright text-size-h5 text-dark-blue mt-2">© <span class="currentYear">2023</span> Girls Code Savannah, Inc.</p>

      </div>

    `
  }
}

// *********************** Define 'extends HTMLElements' Above *********************** //

customElements.define('first-nav', FirstNav);
customElements.define('contact-us', Contact);
customElements.define('lesli-contact', LesliContact);
customElements.define('email-contact-new-classes', ContactForNewClasses);
customElements.define('encourage-popup', EncouragePopup);
customElements.define('footer-nav', Footer);

// *********************** Automatically Update Copyright Year *********************** //

const yearSpan = document.querySelector(".currentYear");
const currentYear = new Date();
yearSpan.innerText = currentYear.getFullYear();

// *********************** Highlight Active Links on Top Navigation *********************** //

document.querySelectorAll(".nav-link").forEach((link) => {
    if (link.href === window.location.href) {
        link.classList.add("active");
        link.setAttribute("aria-current", "page");
    }
});

// **************************** #9: Sign Out (Locally) ************************************** //

  async function handleSignOut() {
      
    try {
      await signOut();
      spinnerHide()
      const currentUser = await getCurrentUser({bypassCache: true});
      // console.log(`CurrentUser: ${currentUser}`);
      
    } catch (error) {
      spinnerHide()
      console.log('Error logging out: ', error);
    

      if (error.name === 'UserUnAuthenticatedException') {
        console.log(`Cognito User Status: User IS NOT Signed In`);
        localStorage.clear()
        window.location = '/index.html'
        toggleNavBar()
      }
    }

  }

// *********** #8: Listen for Sign Out Button Click then Sign Out (Locally) Above *************** //

  const logOutButton = document.getElementById("logout-nav-button");

  logOutButton.addEventListener("click", () => {

    // console.log('Log Out Button Clicked');

    event.preventDefault();

    spinnerShow();

    handleSignOut()

  });

// *********************************** BEGIN: User Auth Functions As Page Loads ********************************* //

  async function handleFetchUserAttributes() {

    // console.log('In handleFetchUserAttributes');

  try {
    const userAttributes = await fetchUserAttributes();
    // console.log(userAttributes);
    // console.log(userAttributes?.given_name);

      // (A) VARIABLES TO PASS TO SESSION STORAGE

      var studentFirstName = userAttributes?.given_name
      var studentNickName = userAttributes?.nickname
 
      // (B) SAVE TO SESSION STORAGE

      if (studentFirstName) {
        localStorage.setItem("studentFirstName", studentFirstName);
      }

      if (studentNickName) {
        localStorage.setItem("studentNickName", studentNickName);
      }

  } catch (error) {
    console.log(error);
  }
}

// ********* Toogle Top Right Navigation Buttons to Hide/Show Top Nav Elements ************ //

const toggleNavBar = () => {

  // console.log('In toggleNavBar');

  if (currentUser) {
    document.querySelector('#login-nav-button').classList.add('hidden')
    document.querySelector('#logout-nav-button').classList.remove('hidden')
    document.querySelector('#my-page-nav-link').classList.remove('hidden')

  } else {
    document.querySelector('#login-nav-button').classList.remove('hidden')
    document.querySelector('#logout-nav-button').classList.add('hidden')
  }

};

// ********* If User Is Authorized (i.e., Logged In) => Toggle Top Right Navigation Buttons ************ //

async function getCurrentUserData() {

  try {
        currentUser = await getCurrentUser({bypassCache: true})

        // console.log("Current User: ", currentUser);

        const cognitoID = currentUser.userId;

        // console.log("userID: ", cognitoID);

        handleFetchUserAttributes()

      // (A) VARIABLES TO PASS TO LOCAL STORAGE

      var cognitoId = cognitoID
 
      // (B) SAVE TO LOCAL STORAGE

      localStorage.setItem("userID", cognitoId);
   
      toggleNavBar()

  } catch (err) {

    // console.log("Error is: ", err);

    if (err.name == "UserUnAuthenticatedException") {
      
      console.log("User is not logged in.");
    }

    if (err.message == "The user is not authenticated") {

      console.log("User is not logged in.");
    }

    if (err.message == "Auth UserPool not configured.") {
  
      console.log("User is not logged in.");
    }

      toggleNavBar()
  }
}

// ********* On Load, Call function to check User Auth ************ //

function getAuthOnLoad() {
  window.addEventListener('load', function() {
    getCurrentUserData()
  })
}

// **** Call getAuthOnLoad function to determine if user is logged in *** //

getAuthOnLoad()

// *********************************** END: User Auth Functions As Page Loads ********************************* //
